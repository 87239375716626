/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Link } from "gatsby"
import {
  FaLinkedin,
  FaRssSquare,
  FaSlideshare,
  FaStackOverflow,
  FaTwitterSquare,
} from "react-icons/fa"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  // const data = useStaticQuery(graphql`
  //   query BioQuery {
  //     site {
  //       siteMetadata {
  //         author {
  //           name
  //           summary
  //         }
  //         social {
  //           twitter
  //         }
  //       }
  //     }
  //   }
  // `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  // const author = data.site.siteMetadata?.author
  // const social = data.site.siteMetadata?.social

  return (
    <div className="bio">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/profile-pic.png"
        width={50}
        height={50}
        quality={95}
        alt="Profile picture"
      />
      <p>
        Webのフロントエンドからバックエンド、プロトタイピングからプロダクションまで。定額の開発サービスも。詳しくは
        <Link style={{}} to="/about">
          About
        </Link>
        ページで。
        <br />
        <a style={{ marginRight: 10 }} href="https://twitter.com/snize">
          <FaTwitterSquare />
        </a>
        <a
          style={{ marginRight: 10 }}
          href="https://stackoverflow.com/users/2484440/snize"
        >
          <FaStackOverflow />
        </a>
        <a
          style={{ marginRight: 10 }}
          href="https://www.linkedin.com/in/tomotsugukaneko/"
        >
          <FaLinkedin />
        </a>
        <a
          style={{ marginRight: 10 }}
          href="https://www.slideshare.net/snize/presentations"
        >
          <FaSlideshare />
        </a>
        <a style={{ marginRight: 10 }} href="/rss.xml">
          <FaRssSquare />
        </a>
      </p>
    </div>
  )
}

export default Bio
